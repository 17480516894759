import styled from "styled-components"
import { motion } from "framer-motion"

export const NavModuleStyles = styled.nav`
  .nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px var(--borderSpacing) 0 var(--borderSpacing);
    z-index: 100;

    @media (min-width: 1024px) {
      padding-top: 50px;
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  .menu {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: var(--menuWidth);
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 30px var(--borderSpacing);
    padding-top: 155px;
    background-color: #000;
    z-index: 99;
    overflow-y: scroll;
    transform: translateX(calc(var(--menuWidth) * -1));

    @media (min-width: 768px) {
      padding-top: 250px;
    }

    @media (min-width: 1024px) {
      flex-direction: row;
    }
  }
`

export const HamburgerStyles = styled(motion.button)`
  background-color: transparent;
  border: none;
  width: 30px;
  height: 30px;
  padding: 0;
  cursor: pointer;
  outline: none;

  &:focus {
    border: none;
    outline: none;
  }

  .bar {
    display: block;
    background-color: #fff;
    height: 2px;
    border-radius: 2px;

    &:nth-of-type(2) {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
`

export const LogoStyles = styled.div`
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-weight: 700;
  font-size: 20px;

  a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  @media (min-width: 1024px) {
    font-size: 25px;
  }

  span {
    color: var(--primary);
  }

  &:hover,
  &:focus {
    a {
      color: var(--primary);
    }
  }
`
