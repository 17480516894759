import { useStaticQuery, graphql } from "gatsby"

const useRecipeCategoriesByTag = () => {
  const { allPrismicRecipeCategory } = useStaticQuery(
    graphql`
      query {
        allPrismicRecipeCategory(sort: { order: ASC, fields: data___order }) {
          group(field: tags) {
            fieldValue
            nodes {
              uid
              data {
                name {
                  text
                }
              }
            }
          }
        }
      }
    `
  )

  return allPrismicRecipeCategory
}

export default useRecipeCategoriesByTag
