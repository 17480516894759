import { useLayoutEffect } from "react"

export default function useLockBodyScroll() {
  useLayoutEffect(
    () => {
      //get original value of body
      const originalStyle = window.scrollY
      //prevent scrolling on mount
      document.body.style.position = "fixed"
      document.body.style.top = `-${originalStyle}px`

      // re-enable scrolling when component unmounts
      return () => {
        const top = window.getComputedStyle(document.body).top

        document.body.style.position = ""
        document.body.style.top = ""
        window.scrollTo({
            top: parseInt(top || '0') * -1,
            behavior: 'instant',});
      }
    },
    [] //empty array to ensures effect is only run when mount and unmount
  )
}

// export default function useLockBodyScroll() {
//   useLayoutEffect(
//     () => {
//       //get original value of body
//       const originalStyle = window.getComputedStyle(document.body).overflow
//       //prevent scrolling on mount
//       document.body.style.overflow = "hidden"
//       // re-enable scrolling when component unmounts
//       return () => (document.body.style.overflow = originalStyle)
//     },
//     [] //empty array to ensures effect is only run when mount and unmount
//   )
// }
