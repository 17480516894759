export const menuItems = [
  {
    path: "/",
    text: "Головна",
  },
  {
    path: "/recipes/",
    text: "Рецепти",
  },
]
