import React, { useContext } from "react"
import { Link } from "gatsby"
import MenuContext from "../MenuContext"
import { motion } from "framer-motion"
import {
  NavModuleStyles,
  HamburgerStyles,
} from "./NavModuleStyles"
import {
  barOneVariants,
  barTwoVariants,
  barThreeVariants,
} from "./NavAnim"

import Logo from "./Logo"
import Menu from "./Menu"

const NavModule = () => {

  const [isOpen, setNav] = useContext(MenuContext)

  const toggleNav = () => {
    setNav((isOpen) => !isOpen)
  }

  return (
    <NavModuleStyles>
      <div className="nav">
        <div className="container">
          <HamburgerStyles
            initial="closed"
            animate={isOpen ? "open" : "closed"}
            onClick={toggleNav}
            aria-label={isOpen ? "Close Menu" : "Open Menu"}
            className={isOpen ? " open" : ""}
          >
            <motion.span
              className="bar"
              variants={barOneVariants}
            ></motion.span>
            <motion.span
              className="bar"
              variants={barTwoVariants}
            ></motion.span>
            <motion.span
              className="bar"
              variants={barThreeVariants}
            ></motion.span>
          </HamburgerStyles>

          <Link to="/">
            <Logo />
          </Link>
        </div>
      </div>
      {isOpen && <Menu isOpen={isOpen} />}
    </NavModuleStyles>
  )
}

export default NavModule
