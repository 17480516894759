// import React, { useContext, useState } from "react"
import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { menuItems } from "./NavConstants"
import useLockBodyScroll from "../../hooks/useLockBodyScroll"
import useRecipeCategoriesByTag from "../../hooks/useRecipeCategoriesByTag"
// import { FiChevronDown as Chevron } from "react-icons/fi"
import { menuList } from "./NavAnim"

const Column = styled.div`
  flex-basis: 100%;

  @media (min-width: 1024px) {
    flex-basis: 33.33%;
  }
`

export const NavTopLevel = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  > li {
    text-transform: capitalize;
    font-size: var(--h2);
    font-weight: 700;
    transition: color 0.3s ease;

    a {
      text-decoration: none;
      color: #fff;

      &:hover {
        color: var(--primary);
      }
    }

    span {
      color: var(--primary);
    }
  }
`

const Menu = ({ isOpen }) => {
  useLockBodyScroll()
  const data = useRecipeCategoriesByTag()

  return (
    <motion.div
      initial="closed"
      animate={isOpen ? "open" : "closed"}
      variants={menuList}
      transition={{ type: "ease", stiffness: 50, velocity: 50 }}
      className="menu"
    >
      <Column>
        <NavTopLevel>
          {menuItems.map((item, index) => (
            <li key={index}>
              <Link to={item.path} activeClassName="menu__item--active">
                {item.text}
                <span>.</span>
              </Link>
            </li>
          ))}
        </NavTopLevel>
      </Column>
      <Column>
        <p>за типом страви</p>
        <NavTopLevel>
          {data.group
            .find((group) => group.fieldValue === "dishes")
            .nodes.map((node, i) => {
              return (
                // <pre>{JSON.stringify(node, null, 4)}</pre>
                <li key={i}>
                  <Link to={`/recipes/${node.uid}/`} activeClassName="menu__item--active">
                    {node.data.name.text}
                    <span>.</span>
                  </Link>
                </li>
              )
            })}
        </NavTopLevel>
      </Column>
      <Column>
        <p>за інгредієнтами</p>
        <NavTopLevel>
          {data.group
            .find((group) => group.fieldValue === "ingredients")
            .nodes.map((node, i) => {
              return (
                // <pre>{JSON.stringify(node, null, 4)}</pre>
                <li key={i}>
                  <Link to={`/recipes/${node.uid}/`} activeClassName="menu__item--active">
                    {node.data.name.text}
                    <span>.</span>
                  </Link>
                </li>
              )
            })}
        </NavTopLevel>
      </Column>
    </motion.div>
  )
}

export default Menu
